@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  background-color: white;
}

.rdw-editor-main {
  background-color: #fff;
  border-left: 0.1px solid transparent;
  position: relative;
  z-index: 1;
}
.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option-selected {
  color: white !important;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-inner
  .side-navigation-panel-select-inner-wrap
  .side-navigation-panel-select-inner-option-selected {
  color: white !important;
}
